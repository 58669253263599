import React, { useContext } from "react";
import { Button, Modal } from "@mui/material";
import { Box } from "@mui/system";
import { AppContext } from "../context/AppContext";

const ConfirmShare = ({ distribute, setDistribute }) => {
  const { url } = useContext(AppContext);
  const copyLinkToClipboard = () => {
    const linkElement = document.querySelector(".text-black");
    if (linkElement && linkElement.href) {
      navigator.clipboard.writeText(linkElement.href);
    }
  };

  return (
    <>
      <Modal
        open={distribute}
        onClose={() => setDistribute(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 300,
            width: "calc(100vw - 16px)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: "4px",
            textAlign: "center",
            overflow: "auto",
            maxHeight: "calc(100vh - 16px)",
          }}
        >
          <div>
            <a
              href={`${url}/shareleads`}
              target="_blank"
              className="text-black p-2 border rounded mb-2 d-block"
            >
              {url}/shareleads
            </a>
          </div>
          <div className="d-flex align-items-center justify-content-end gap-2">
            <Button
              variant="contained"
              className="text-white"
              onClick={copyLinkToClipboard}
            >
              Copy Link
            </Button>
            <Button
              variant="contained"
              color="danger"
              className="text-white"
              onClick={() => setDistribute(false)}
            >
              Close
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ConfirmShare;
