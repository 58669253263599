import React, { useContext } from "react";
import { Button, Modal } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import Cookies from "js-cookie";
import { AppContext } from "../context/AppContext";

const ConfirmWithhold = ({
  withholdModal,
  setWithholdModal,
  withholdLead,
  sharedByUser,
}) => {
  const { url } = useContext(AppContext);
  const withhold = async () => {
    const dsa_user = Cookies.get("dsa_user");
    try {
      const res = await axios.post(
        `${url}/user/lead/withhold`,
        {
          lead: withholdLead._id,
        },
        {
          headers: {
            token: dsa_user,
          },
        }
      );
      if (res.data.success === true) {
        setWithholdModal(false);
        sharedByUser();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Modal
        open={withholdModal}
        onClose={() => setWithholdModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 300,
            width: "calc(100vw - 16px)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: "4px",
            textAlign: "center",
            overflow: "auto",
            maxHeight: "calc(100vh - 16px)",
          }}
        >
          <>
            <h2 className="mb-4">
              Withhold {withholdLead !== null && withholdLead.customerName}?
            </h2>
          </>
          <div className="d-flex align-items-center justify-content-center gap-2">
            <Button
              color="danger"
              className="text-white"
              variant="contained"
              onClick={withhold}
            >
              Yes
            </Button>
            <Button
              color="primary"
              onClick={() => setWithholdModal(false)}
              variant="contained"
              className="text-white"
            >
              No
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ConfirmWithhold;
