import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import { Button } from "@mui/material";
import ConfirmLogout from "../components/ConfirmLogout";
import ConfirmDeleteAccount from "../components/ConfirmDeleteAccount";
import moment from "moment";
import SnackBar from "../components/Snackbar";
import EditProfile from "../components/EditProfile";
import profilePic from "../assets/images/profile-pic.jfif";

const Profile = () => {
  const { user, url, getUser } = useContext(AppContext);
  const [logoutModal, setLogoutModal] = useState(false);
  const [deleteAccountModal, setDeleteAccountModal] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [editProfileModal, setEditProfileModal] = useState(false);
  useEffect(() => {
    getUser();
  }, []);
  return (
    <>
      <div className="container-fluid p-2">
        {user !== null ? (
          <div>
            <div className="user-profile-grid">
              <div className="user-profile-pic">
                <img
                  src={
                    !user.profile_pic
                      ? profilePic
                      : `${url}/${user.profile_pic}`
                  }
                  alt="profile"
                />
              </div>
              <div>
                <p>
                  Name: <span className="fw-bold">{user.name}</span>
                </p>
                <p>
                  Username: <span className="fw-bold">{user.username}</span>
                </p>
                <p>
                  Account status:{" "}
                  <span
                    className={`fw-bold ${
                      user.active === true ? "text-success" : "text-danger"
                    }`}
                  >
                    {user.active === true ? "Active" : "Inactive"}
                  </span>
                </p>
                <p>
                  Leads added by you:{" "}
                  <span className="fw-bold">
                    {user.leads && user.leads.length}
                  </span>
                </p>
                <p>
                  Account created at:{" "}
                  <span className="fw-bold">
                    {moment(user.createdAt).format("Do MMMM YYYY, h:mm:ss a")}
                  </span>
                </p>
                <div className="d-flex align-items-center justify-content-start gap-2 mt-4">
                  <Button
                    variant="contained"
                    color="primary"
                    className="text-white"
                    onClick={
                      user !== null && user.active === true
                        ? () => setEditProfileModal(true)
                        : () => setOpenSnackbar(true)
                    }
                  >
                    Edit profile
                  </Button>
                  <Button
                    variant="contained"
                    color="danger"
                    className="text-white"
                    onClick={() => setLogoutModal(true)}
                  >
                    Logout
                  </Button>
                </div>
              </div>
            </div>
            <hr />
            <div className="text-end">
              <Button
                variant="contained"
                color="danger"
                className="text-white"
                onClick={
                  user !== null && user.active === true
                    ? () => setDeleteAccountModal(true)
                    : () => setOpenSnackbar(true)
                }
              >
                Delete account
              </Button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <ConfirmLogout
        logoutModal={logoutModal}
        setLogoutModal={setLogoutModal}
      />

      <ConfirmDeleteAccount
        deleteAccountModal={deleteAccountModal}
        setDeleteAccountModal={setDeleteAccountModal}
      />

      <SnackBar openSnackbar={openSnackbar} setOpenSnackbar={setOpenSnackbar} />

      <EditProfile
        editProfileModal={editProfileModal}
        setEditProfileModal={setEditProfileModal}
      />
    </>
  );
};

export default Profile;
