import React, { useContext, useEffect, useState } from "react";
import { Button, TextField, AppBar, Toolbar } from "@mui/material";
import axios from "axios";
import { AppContext } from "../context/AppContext";
import { Link } from "react-router-dom";

const SharedLeads = () => {
  const { url } = useContext(AppContext);
  const [inputs, setInputs] = useState({
    customerName: "",
    customerNumber: "",
    email: "",
    creditorsdebtors: "",
    companyName: "",
    city: "",
    leadUniqueID: "",
  });
  const handleInputs = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };
  //
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");
  const submitLead = async () => {
    const {
      customerName,
      customerNumber,
      creditorsdebtors,
      companyName,
      email,
      city,
      leadUniqueID,
    } = inputs;
    try {
      const res = await axios.post(`${url}/user/lead/client`, {
        customerName,
        customerNumber,
        creditorsdebtors,
        companyName,
        email,
        city,
        leadUniqueID,
      });
      if (res.data.success === true) {
        setIsError(false);
        setMessage(res.data.message);
        setInputs({
          customerName: "",
          customerNumber: "",
          email: "",
          creditorsdebtors: "",
          companyName: "",
          city: "",
          leadUniqueID: "",
        });
      } else {
        setIsError(true);
        setMessage(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //
  useEffect(() => {
    if (message !== "") {
      setTimeout(() => {
        setMessage("");
      }, 3000);
    }
  }, [message]);
  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          <p className="text-white">DSA</p>
        </Toolbar>
      </AppBar>
      {/*  */}
      <div className="container p-2">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 col-md-8">
            <h2>Submit lead</h2>
            <form>
              <TextField
                label="Customer Name"
                variant="standard"
                fullWidth
                type="text"
                margin="normal"
                name="customerName"
                value={inputs.customerName}
                onChange={handleInputs}
                autoComplete="off"
              />
              <TextField
                label="Customer Number"
                variant="standard"
                fullWidth
                type="number"
                margin="normal"
                name="customerNumber"
                value={inputs.customerNumber}
                onChange={handleInputs}
                autoComplete="off"
              />
              <TextField
                label="Email address"
                variant="standard"
                fullWidth
                type="email"
                margin="normal"
                name="email"
                value={inputs.email}
                onChange={handleInputs}
                autoComplete="off"
              />
              <TextField
                label="Total Creditors/Debtors"
                variant="standard"
                fullWidth
                type="number"
                margin="normal"
                name="creditorsdebtors"
                value={inputs.creditorsdebtors}
                onChange={handleInputs}
                autoComplete="off"
              />
              <TextField
                label="Company name"
                variant="standard"
                fullWidth
                type="text"
                margin="normal"
                name="companyName"
                value={inputs.companyName}
                onChange={handleInputs}
                autoComplete="off"
              />
              <TextField
                label="City"
                variant="standard"
                fullWidth
                type="text"
                margin="normal"
                name="city"
                value={inputs.city}
                onChange={handleInputs}
                autoComplete="off"
              />
              <TextField
                label="Lead Unique ID"
                variant="standard"
                fullWidth
                type="text"
                margin="normal"
                name="leadUniqueID"
                value={inputs.leadUniqueID}
                onChange={handleInputs}
                autoComplete="off"
              />
              <div className="text-end">
                <p className={isError ? "text-danger" : "text-success"}>
                  {message}
                </p>
                <Button
                  color="primary"
                  variant="contained"
                  className="text-white"
                  onClick={submitLead}
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SharedLeads;
