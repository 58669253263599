import axios from "axios";
import Cookies from "js-cookie";
import React, { createContext, useEffect, useState } from "react";
const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const url = "http://localhost:5010";
  const [user, setUser] = useState(null);
  //
  const [userLeads, setUserLeads] = useState([]);
  const [userLeads2, setUserLeads2] = useState([]);
  //
  const getUser = async () => {
    const dsa_user = Cookies.get("dsa_user");
    const res = await axios.get(`${url}/user/auth`, {
      headers: {
        token: dsa_user,
      },
    });
    if (res.data.success === true) {
      setUser(res.data.user);
      setUserLeads(res.data.user.leads);
      setUserLeads2(res.data.user.leads);
    }
  };
  //
  const removeUnwanted = async () => {
    const dsa_user = Cookies.get("dsa_user");
    await axios.get(`${url}/user/lead/delete/unwanted`, {
      headers: {
        token: dsa_user,
      },
    });
  };
  useEffect(() => {
    if (user !== null) {
      removeUnwanted();
    }
  }, [user]);
  //
  return (
    <AppContext.Provider
      value={{
        url,
        user,
        setUser,
        getUser,
        userLeads,
        userLeads2,
        setUserLeads,
        setUserLeads2,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
