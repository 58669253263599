import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./context/Theme";
import Home from "./pages/Home";
import SignUp from "./pages/SignUp";
import Login from "./pages/Login";
import User from "./pages/User";
import Auth from "./utility/Auth";
import { AppContextProvider } from "./context/AppContext";
import AdminLeads from "./pages/AdminLeads";
import ShareLeads from "./pages/ShareLeads";
import Profile from "./pages/Profile";
import PageNotFound from "./pages/PageNotFound";
import LeadUniqueID from "./pages/LeadUniqueID";

const App = () => {
  return (
    <AppContextProvider>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" element={<Auth Component={Home} />} />
          {/*  */}
          <Route path="/shareleads" element={<ShareLeads />} />
          <Route
            path="/leaduniqueid"
            element={<Auth Component={LeadUniqueID} />}
          />
          <Route path="/adminleads" element={<Auth Component={AdminLeads} />} />
          <Route path="/users" element={<Auth Component={User} />} />
          <Route path="/profile" element={<Auth Component={Profile} />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/pagenotfound" element={<PageNotFound />} />
        </Routes>
      </ThemeProvider>
    </AppContextProvider>
  );
};

export default App;
