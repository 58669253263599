import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import axios from "axios";
import { AppContext } from "../context/AppContext";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import Cookies from "js-cookie";

const LeadUniqueID = () => {
  const [inputs, setInputs] = useState("");
  const { url, user } = useContext(AppContext);
  const addLeadUniqueID = async () => {
    const dsa_user = Cookies.get("dsa_user");
    try {
      const res = await axios.post(
        `${url}/leaduniqueid`,
        {
          leadUniqueID: inputs,
          userId: user._id,
        },
        {
          headers: {
            token: dsa_user,
          },
        }
      );
      if (res.data.success === true) {
        setInputs("");
        getLeadUnique();
      }
    } catch (error) {
      console.log(error);
    }
  };
  //
  const [data, setData] = useState([]);
  const getLeadUnique = async () => {
    const dsa_user = Cookies.get("dsa_user");
    try {
      const res = await axios.get(`${url}/leaduniqueid`, {
        headers: {
          token: dsa_user,
        },
      });
      if (res.data.success === true) {
        setData(res.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getLeadUnique();
  }, []);
  //
  const deleteLeadUniqueID = async (item) => {
    const dsa_user = Cookies.get("dsa_user");
    const confirmDelete = window.confirm(`Delete ${item.leadUniqueID}?`);
    if (confirmDelete) {
      const res = await axios.delete(`${url}/leaduniqueid/${item._id}`, {
        headers: {
          token: dsa_user,
        },
      });
      if (res.data.success === true) {
        getLeadUnique();
      }
    }
  };
  //
  const [editStatus, setEditStatus] = useState(false);
  const [editID, setEditID] = useState(false);
  const startEdit = (item) => {
    setEditStatus(true);
    setEditID(item._id);
    setInputs(item.leadUniqueID);
  };
  const editLeadUniqueID = async () => {
    try {
      const dsa_user = Cookies.get("dsa_user");
      const res = await axios.put(
        `${url}/leaduniqueid/${editID}`,
        {
          leadUniqueID: inputs,
        },
        {
          headers: {
            token: dsa_user,
          },
        }
      );
      if (res.data.success === true) {
        setEditStatus(false);
        setEditID("");
        getLeadUnique();
        setInputs("");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="container-fluid p-2">
      <div className="d-flex align-items-center justify-content-between gap-2">
        <TextField
          label="Lead Unique ID"
          variant="standard"
          fullWidth
          type="text"
          margin="normal"
          name="customerName"
          value={inputs}
          onChange={(e) => setInputs(e.target.value)}
          autoComplete="off"
        />
        {editStatus ? (
          <Button
            color="warning"
            variant="contained"
            className="text-white"
            onClick={editLeadUniqueID}
          >
            Edit
          </Button>
        ) : (
          <Button
            color="primary"
            variant="contained"
            className="text-white"
            onClick={addLeadUniqueID}
          >
            Submit
          </Button>
        )}
      </div>
      <hr />
      {data.length === 0 ? (
        <p className="text-center">No data found</p>
      ) : (
        <TableContainer component={Paper} className="table-container">
          <Table>
            <TableHead>
              <TableRow className="table-background">
                <TableCell className="border" sx={{ minWidth: "100px" }}>
                  Sr No
                </TableCell>
                <TableCell className="border" sx={{ minWidth: "200px" }}>
                  Name
                </TableCell>
                <TableCell
                  className="border"
                  sx={{ minWidth: "100px" }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell className="border">{index + 1}</TableCell>
                    <TableCell className="border">
                      <span className="text-clamp">{item.leadUniqueID}</span>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        color="primary"
                        onClick={() => startEdit(item)}
                      >
                        <CreateIcon />
                      </IconButton>
                      <IconButton
                        color="danger"
                        onClick={() => deleteLeadUniqueID(item)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default LeadUniqueID;
