import React, { useContext, useMemo, useState } from "react";
import { Button, IconButton, Modal, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import Cookies from "js-cookie";
import axios from "axios";
import { AppContext } from "../context/AppContext";

const AddLead = ({ addModal, setAddModal }) => {
  const { url, getUser } = useContext(AppContext);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");
  const [inputs, setInputs] = useState({
    customerName: "",
    customerNumber: "",
    email: "",
    creditorsdebtors: "",
    companyName: "",
    city: "",
  });
  const handleInputs = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };
  //
  const postLead = async (e) => {
    e.preventDefault();
    //
    if (!inputs.customerName) {
      setIsError(true);
      setMessage("Enter customer name");
    } else if (!inputs.customerNumber) {
      setIsError(true);
      setMessage("Enter customer mobile number");
    } else if (!inputs.creditorsdebtors) {
      setIsError(true);
      setMessage("Enter total number of creditors");
    } else if (inputs.creditorsdebtors < 2) {
      setIsError(true);
      setMessage("Not eligible");
    } else if (!inputs.email) {
      setIsError(true);
      setMessage("Enter email address");
    } else if (!inputs.city) {
      setIsError(true);
      setMessage("Enter city");
    } else {
      const dsa_user = Cookies.get("dsa_user");
      try {
        const res = await axios.post(
          `${url}/user/lead`,
          {
            customerName: inputs.customerName,
            customerNumber: inputs.customerNumber,
            creditorsdebtors: inputs.creditorsdebtors,
            companyName: inputs.companyName,
            email: inputs.email,
            city: inputs.city,
          },
          {
            headers: {
              token: dsa_user,
            },
          }
        );
        if (res.data.success === true) {
          getUser();
          setInputs({
            customerName: "",
            customerNumber: "",
            creditorsdebtors: "",
            companyName: "",
            email: "",
            city: "",
          });
          setAddModal(false);
          setIsError(false);
          setMessage(res.data.message);
        } else if (res.data.success === false) {
          setIsError(true);
          setMessage(res.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  useMemo(() => {
    if (message !== "") {
      setTimeout(() => {
        setMessage("");
      }, 2000);
    }
  }, [message]);
  return (
    <>
      <Modal
        open={addModal}
        onClose={() => setAddModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 400,
            width: "calc(100vw - 16px)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: "4px",
            overflow: "auto",
            maxHeight: "calc(100vh - 16px)",
          }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <h2>Add a lead</h2>
            <IconButton aria-label="close" onClick={() => setAddModal(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <>
            <form>
              <TextField
                label="Customer Name"
                variant="standard"
                fullWidth
                type="text"
                margin="normal"
                name="customerName"
                value={inputs.customerName}
                onChange={handleInputs}
                autoComplete="off"
              />
              <TextField
                label="Customer Number"
                variant="standard"
                fullWidth
                type="number"
                margin="normal"
                name="customerNumber"
                value={inputs.customerNumber}
                onChange={handleInputs}
                autoComplete="off"
              />
              <TextField
                label="Email address"
                variant="standard"
                fullWidth
                type="email"
                margin="normal"
                name="email"
                value={inputs.email}
                onChange={handleInputs}
                autoComplete="off"
              />
              <TextField
                label="Total Creditors/Debtors"
                variant="standard"
                fullWidth
                type="number"
                margin="normal"
                name="creditorsdebtors"
                value={inputs.creditorsdebtors}
                onChange={handleInputs}
                autoComplete="off"
              />
              <TextField
                label="Company name"
                variant="standard"
                fullWidth
                type="text"
                margin="normal"
                name="companyName"
                value={inputs.companyName}
                onChange={handleInputs}
                autoComplete="off"
              />
              <TextField
                label="City"
                variant="standard"
                fullWidth
                type="text"
                margin="normal"
                name="city"
                value={inputs.city}
                onChange={handleInputs}
                autoComplete="off"
              />
              <div className="text-end">
                <p className={isError ? "text-danger" : "text-success"}>
                  {message}
                </p>
                <Button
                  color="primary"
                  variant="contained"
                  className="text-white"
                  onClick={postLead}
                >
                  Submit
                </Button>
              </div>
            </form>
          </>
        </Box>
      </Modal>
    </>
  );
};

export default AddLead;
