import React from "react";

const PageNotFound = () => {
  return (
    <div className="text-center container py-4 p-2">
      <h2>Page not found</h2>
    </div>
  );
};

export default PageNotFound;
