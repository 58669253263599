import React, { useContext, useMemo, useState } from "react";
import { Card, CardContent, TextField, Button } from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import { AppContext } from "../context/AppContext";
import Cookies from "js-cookie";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const SignUp = () => {
  const { url } = useContext(AppContext);
  const [inputs, setInputs] = useState({
    name: "",
    username: "",
    password: "",
    mobile: "",
    email: "",
    secretkey: "",
  });
  const handleInputs = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };
  //
  const [passwordShow, setPasswordShow] = useState(false);
  const [secretKeyShow, setSecretKeyShow] = useState(false);
  const handlePasswordShow = (e) => {
    setPasswordShow(!passwordShow);
  };
  const handleSecretKeyShow = (e) => {
    setSecretKeyShow(!secretKeyShow);
  };
  //
  const [isErr, setIsErr] = useState(false);
  const [msg, setMsg] = useState("");
  const [loginTimer, setLoginTimer] = useState(2000);
  const handleSignup = async (e) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const usernameRegex = /^[a-zA-Z0-9_.]+$/;
    e.preventDefault();
    const { name, username, password, mobile, email, secretkey } = inputs;
    if (!name) {
      setIsErr(true);
      setMsg("Enter your name");
    } else if (name.length < 3) {
      setIsErr(true);
      setMsg("Minimum 3 characted required for name");
    } else if (!username) {
      setIsErr(true);
      setMsg("Enter username");
    } else if (username.length < 3) {
      setIsErr(true);
      setMsg("Minimum 3 characted required for username");
    } else if (usernameRegex.test(username) === false) {
      setIsErr(true);
      setMsg("Invalid username");
    } else if (!mobile) {
      setIsErr(true);
      setMsg("Enter mobile number");
    } else if (!email) {
      setIsErr(true);
      setMsg("Enter email address");
    } else if (emailRegex.test(email) === false) {
      setIsErr(true);
      setMsg("Enter a valid email address");
    } else if (!password) {
      setIsErr(true);
      setMsg("Enter password");
    } else if (!secretkey) {
      setIsErr(true);
      setMsg("Enter secretkey");
    } else {
      const dsa_user = Cookies.get("dsa_user");
      const res = await axios.post(
        `${url}/user/signup`,
        {
          name,
          username,
          password,
          mobile,
          email,
          secretkey,
        },
        {
          headers: {
            token: dsa_user,
          },
        }
      );
      //
      if (res.data.success === true) {
        setLoginTimer(10000);
        setIsErr(false);
        setMsg(res.data.message);
        setInputs({
          name: "",
          username: "",
          password: "",
          mobile: "",
          email: "",
          secretkey: "",
        });
      } else {
        setLoginTimer(2000);
        setIsErr(true);
        setMsg(res.data.message);
      }
    }
  };
  useMemo(() => {
    if (msg !== "") {
      setTimeout(() => {
        setMsg("");
      }, loginTimer);
    }
  }, [msg]);
  return (
    <>
      <div className="d-flex align-items-center justify-content-center px-2">
        <Card
          style={{ maxWidth: "400px", width: "400px", margin: "10px auto" }}
        >
          <div className="p-2">
            <div className="auth-title-box rounded">
              <h2>DSA CRM</h2>
            </div>
          </div>
          <CardContent>
            <h2>Sign Up</h2>
            <form>
              <TextField
                label="Name"
                type="text"
                variant="standard"
                fullWidth
                margin="normal"
                className="mt-0"
                autoComplete="off"
                name="name"
                value={inputs.name}
                onChange={handleInputs}
              />
              <TextField
                label="Username"
                type="text"
                variant="standard"
                fullWidth
                margin="normal"
                autoComplete="off"
                name="username"
                value={inputs.username}
                onChange={handleInputs}
              />
              <TextField
                label="Mobile number"
                type="number"
                variant="standard"
                fullWidth
                margin="normal"
                autoComplete="off"
                name="mobile"
                value={inputs.mobile}
                onChange={handleInputs}
              />
              <TextField
                label="Email address"
                type="email"
                variant="standard"
                fullWidth
                margin="normal"
                autoComplete="off"
                name="email"
                value={inputs.email}
                onChange={handleInputs}
              />
              <div className="password-div">
                <TextField
                  label="Password"
                  type={passwordShow === true ? "text" : "password"}
                  variant="standard"
                  fullWidth
                  margin="normal"
                  autoComplete="off"
                  name="password"
                  value={inputs.password}
                  onChange={handleInputs}
                />
                <div className="password-show" onClick={handlePasswordShow}>
                  {passwordShow === true ? (
                    <VisibilityOffIcon color="primary" />
                  ) : (
                    <VisibilityIcon color="primary" />
                  )}
                </div>
              </div>
              <div className="password-div">
                <TextField
                  label="Secretkey"
                  type={secretKeyShow === true ? "text" : "password"}
                  variant="standard"
                  fullWidth
                  margin="normal"
                  autoComplete="off"
                  name="secretkey"
                  value={inputs.secretkey}
                  onChange={handleInputs}
                />
                <div className="password-show" onClick={handleSecretKeyShow}>
                  {secretKeyShow === true ? (
                    <VisibilityOffIcon color="primary" />
                  ) : (
                    <VisibilityIcon color="primary" />
                  )}
                </div>
              </div>
              <p
                className={
                  isErr ? "text-danger text-end" : "text-success text-start"
                }
              >
                {msg}
              </p>
              <div className="text-end">
                <Button
                  variant="contained"
                  className="text-white"
                  color="primary"
                  onClick={handleSignup}
                >
                  Sign Up
                </Button>
              </div>
            </form>
            <hr />
            <div className="d-flex align-items-center justify-content-center gap-2">
              <p>Already have an account?</p>
              <Link to="/login" className="primary-text">
                <Button
                  variant="contained"
                  className="text-white"
                  color="primary"
                >
                  Login
                </Button>
              </Link>
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default SignUp;
