import React, { useContext, useRef, useState } from "react";
import { AppContext } from "../context/AppContext";
import ViewLead from "../components/ViewLead";
import AddLead from "../components/AddLead";
import EditLead from "../components/EditLead";
import DeleteLead from "../components/DeleteLead";
import UploadLead from "../components/UploadLead";
import SearchFilter from "../components/SearchFilter";
import Cookies from "js-cookie";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ButtonGroup,
  IconButton,
  TextField,
  Checkbox,
  TablePagination,
  Button,
} from "@mui/material";
//
import VisibilityIcon from "@mui/icons-material/Visibility";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import TuneIcon from "@mui/icons-material/Tune";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ConfirmShare from "../components/ConfirmShare";
import DeleteMultipleLeads from "../components/DeleteMultipleLeads";
import NoDataFound from "../components/NoDataFound";
import SnackBar from "../components/Snackbar";
import IosShareIcon from "@mui/icons-material/IosShare";
import ShareModal from "../components/ShareModal";
//

const Home = () => {
  const {
    user,
    url,
    getUser,
    userLeads,
    userLeads2,
    setUserLeads,
    setUserLeads2,
  } = useContext(AppContext);
  //
  const [selected, setSelected] = useState([]);
  const handleSelected = (e, item) => {
    if (e.target.checked === true) {
      setSelected([...selected, item._id]);
    } else if (e.target.checked === false) {
      const filter = selected.filter((item2) => {
        return item2 !== item._id;
      });
      setSelected(filter);
    }
  };
  //
  const selectAllLeads = () => {
    const leadID = [];
    userLeads2.forEach((item) => {
      leadID.push(item._id);
    });
    setSelected(leadID);
  };
  //
  const [indexSorted, setIndexSorted] = useState(false);
  const indexArrow = useRef(null);
  const sortIndex = () => {
    setIndexSorted(!indexSorted);
    indexArrow.current.classList.toggle("rotate");
    setUserLeads2(userLeads2.reverse());
  };
  //
  const [creditorSorted, setCreditorSorted] = useState(false);
  const creditorArrow = useRef(null);
  const sortCreditors = () => {
    if (creditorSorted === true) {
      setCreditorSorted(false);
      const sorted = userLeads2.sort(
        (a, b) => b.creditorsdebtors - a.creditorsdebtors
      );
      setUserLeads2(sorted);
      creditorArrow.current.classList.add("rotate");
    } else if (creditorSorted === false) {
      setCreditorSorted(true);
      const sorted = userLeads2.sort(
        (a, b) => a.creditorsdebtors - b.creditorsdebtors
      );
      setUserLeads2(sorted);
      creditorArrow.current.classList.remove("rotate");
    }
  };
  //
  const [sliderValue1, setSliderValue1] = useState([0, 0]);
  const handleSliderChange1 = (e, newValue) => {
    setSliderValue1(newValue);
  };
  //
  const [sliderValue2, setSliderValue2] = useState([0, 0]);
  const handleSliderChange2 = (e, newValue) => {
    setSliderValue2(newValue);
  };
  //
  const [status, setStatus] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const applyFilter = () => {
    let updated = userLeads;
    if (searchInput !== "") {
      updated = updated.filter((item) => {
        return (
          item.customerName.toLowerCase().includes(searchInput.toLowerCase()) ||
          item.customerNumber
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          item.companyName.toLowerCase().includes(searchInput.toLowerCase())
        );
      });
    }
    if (sliderValue1[0] >= 1 && sliderValue1[1] <= 50) {
      updated = updated.filter((item) => {
        return (
          parseFloat(item.creditorsdebtors) >= sliderValue1[0] &&
          parseFloat(item.creditorsdebtors) <= sliderValue1[1]
        );
      });
    }
    if (status !== "") {
      updated = updated.filter((item) => {
        return item.status.toLowerCase() == status.toLowerCase();
      });
    }
    //
    setUserLeads2(updated);
    setFilterModal(false);
    setSelected([]);
  };
  const resetFilter = () => {
    setSliderValue1([0, 0]);
    setSliderValue2([0, 0]);
    setUserLeads2(userLeads);
    setFilterModal(false);
    setSearchInput("");
    setStatus("");
  };
  //
  const [viewModal, setViewModal] = useState(false);
  const [details, setDetails] = useState(null);
  const handleViewModal = (item) => {
    setViewModal(true);
    setDetails(item);
  };
  //
  const [addModal, setAddModal] = useState(false);
  //
  const [editModal, setEditModal] = useState(false);
  const [edits, setEdits] = useState(null);
  const handleEdits = async (item) => {
    setEditModal(true);
    try {
      const dsa_user = Cookies.get("dsa_user");
      const res = await axios.get(`${url}/user/lead/${item._id}`, {
        headers: {
          token: dsa_user,
        },
      });
      if (res.data.success === true) {
        setEdits(res.data.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleEditInputs = (e) => {
    const { name, value } = e.target;
    setEdits({
      ...edits,
      [name]: value,
    });
  };
  //
  const [deleleModal, setDeleleModal] = useState(false);
  const [confirmLead, setConfirmLead] = useState(null);
  const confirmDeleteModal = (item) => {
    setConfirmLead(item);
    setDeleleModal(true);
  };
  //
  const [uploadModal, setUploadModal] = useState(false);
  //
  const [filterModal, setFilterModal] = useState(false);
  //
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };
  const [jumpToPage, setJumpToPage] = useState(1);
  const handleJumpToPage = () => {
    const totalPages = Math.ceil(totalCount / rowsPerPage);
    const newPage = Math.min(Math.max(jumpToPage - 1, 0), totalPages - 1);
    setPage(newPage);
  };
  //
  const [shareModal, setShareModal] = useState(false);
  //
  const [rowIndex, setRowIndex] = useState(null);
  const handleMouseEnter = (index) => {
    setRowIndex(index);
  };
  const handleMouseLeave = () => {
    setRowIndex(null);
  };
  //
  const [deleteManyModal, setDeleteManyModal] = useState(false);
  //
  const [openSnackbar, setOpenSnackbar] = useState(false);
  //
  const [distribute, setDistribute] = useState(false);
  return (
    <>
      <div className="container-fluid p-2">
        <div className="top-bar-grid mb-2">
          <div>
            <TextField
              label="Search"
              variant="standard"
              fullWidth
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              onKeyUp={applyFilter}
              autoComplete="off"
              disabled={userLeads.length === 0 ? true : false}
            />
          </div>
          <div className="text-end">
            <IconButton
              color="primary"
              aria-label="upload file"
              component="span"
              onClick={() => setDistribute(true)}
            >
              <IosShareIcon />
            </IconButton>

            <IconButton
              color="primary"
              aria-label="upload file"
              component="span"
              onClick={
                user !== null && user.active === true
                  ? () => setAddModal(true)
                  : () => setOpenSnackbar(true)
              }
            >
              <AddIcon />
            </IconButton>

            <IconButton
              color="primary"
              aria-label="upload file"
              component="span"
              onClick={
                user !== null && user.active === true
                  ? () => setUploadModal(true)
                  : () => setOpenSnackbar(true)
              }
            >
              <CloudUploadIcon />
            </IconButton>

            <IconButton
              color="primary"
              aria-label="upload file"
              component="span"
              onClick={() => setFilterModal(true)}
              disabled={userLeads.length === 0 ? true : false}
            >
              <TuneIcon />
            </IconButton>
          </div>
        </div>
        {userLeads2.length !== 0 ? (
          <>
            {/*  */}
            <TableContainer component={Paper} className="table-container">
              <Table>
                <TableHead>
                  <TableRow className="table-background">
                    <TableCell className="border" sx={{ minWidth: "75px" }}>
                      {selected.length === userLeads2.length ? (
                        <IconButton
                          color="primary"
                          onClick={() => setSelected([])}
                        >
                          <IndeterminateCheckBoxIcon />
                        </IconButton>
                      ) : (
                        <Checkbox onChange={selectAllLeads} />
                      )}
                    </TableCell>
                    <TableCell className="border" sx={{ minWidth: "100px" }}>
                      Sr No
                      <ArrowDownwardIcon
                        onClick={sortIndex}
                        className="indexArrow"
                        ref={indexArrow}
                      />
                    </TableCell>
                    <TableCell className="border" sx={{ minWidth: "200px" }}>
                      Name
                    </TableCell>
                    <TableCell className="border" sx={{ minWidth: "150px" }}>
                      Mobile number
                    </TableCell>
                    <TableCell className="border" sx={{ minWidth: "150px" }}>
                      Email address
                    </TableCell>
                    <TableCell className="border" sx={{ minWidth: "200px" }}>
                      Company name
                    </TableCell>
                    <TableCell className="border" sx={{ minWidth: "200px" }}>
                      City
                    </TableCell>
                    <TableCell className="border" sx={{ minWidth: "250px" }}>
                      Total Creditors/Debtors{" "}
                      <ArrowDownwardIcon
                        onClick={sortCreditors}
                        className="creditorArrow"
                        ref={creditorArrow}
                      />
                    </TableCell>
                    <TableCell className="border" sx={{ minWidth: "250px" }}>
                      Status
                    </TableCell>
                    <TableCell
                      className="border text-end"
                      sx={{ minWidth: "200px" }}
                    >
                      {selected.length === 0 ? (
                        ""
                      ) : (
                        <>
                          {selected.length === userLeads2.length ? (
                            ""
                          ) : (
                            <IconButton
                              color="primary"
                              onClick={() => setSelected([])}
                            >
                              <IndeterminateCheckBoxIcon />
                            </IconButton>
                          )}
                          <IconButton
                            color="danger"
                            onClick={
                              user !== null && user.active === true
                                ? () => setDeleteManyModal(true)
                                : () => setOpenSnackbar(true)
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {userLeads2.map((item, index) => {
                    return (
                      <TableRow
                        key={index}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                        className={
                          index === rowIndex || selected.includes(item._id)
                            ? "table-background"
                            : ""
                        }
                      >
                        <TableCell className="border" sx={{ width: "70px" }}>
                          <Checkbox
                            onChange={(e) => handleSelected(e, item)}
                            checked={selected.includes(item._id)}
                          />
                        </TableCell>
                        <TableCell className="border">{index + 1}</TableCell>
                        <TableCell className="border">
                          <span className="text-clamp">
                            {item.customerName}
                          </span>
                        </TableCell>
                        <TableCell className="border">
                          {item.customerNumber}
                        </TableCell>
                        <TableCell className="border">{item.email}</TableCell>
                        <TableCell className="border">
                          <span className="text-clamp">{item.companyName}</span>
                        </TableCell>
                        <TableCell className="border">{item.city}</TableCell>
                        <TableCell className="border">
                          {item.creditorsdebtors}
                        </TableCell>
                        <TableCell className="border">{item.status}</TableCell>
                        <TableCell className="border">
                          <ButtonGroup>
                            <IconButton
                              color="primary"
                              onClick={() => handleViewModal(item)}
                            >
                              <VisibilityIcon />
                            </IconButton>
                            {selected.length === 0 ? (
                              <IconButton
                                color="primary"
                                onClick={
                                  user !== null && user.active === true
                                    ? () => handleEdits(item)
                                    : () => setOpenSnackbar(true)
                                }
                              >
                                <CreateIcon />
                              </IconButton>
                            ) : (
                              ""
                            )}
                            {selected.length === 0 ? (
                              <IconButton
                                color="danger"
                                onClick={
                                  user !== null && user.active === true
                                    ? () => confirmDeleteModal(item)
                                    : () => setOpenSnackbar(true)
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            ) : (
                              ""
                            )}
                          </ButtonGroup>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[25, 50, 75, 100]}
            />
            {/*  */}
            <div className="d-flex align-items-end justify-content-end gap-2">
              <TextField
                label="Jump to Page"
                type="number"
                value={jumpToPage}
                onChange={(e) => setJumpToPage(e.target.value)}
                variant="standard"
              />
              <Button
                variant="contained"
                color="primary"
                className="text-white"
                onClick={handleJumpToPage}
              >
                Jump
              </Button>
            </div>
          </>
        ) : (
          <NoDataFound />
        )}

        {/* View */}
        <ViewLead
          viewModal={viewModal}
          setViewModal={setViewModal}
          details={details}
        />

        {/* Add */}
        <AddLead addModal={addModal} setAddModal={setAddModal} />

        {/* Edit */}
        <EditLead
          editModal={editModal}
          setEditModal={setEditModal}
          edits={edits}
          setEdits={setEdits}
          handleEditInputs={handleEditInputs}
        />

        {/* Delete */}
        <DeleteLead
          deleleModal={deleleModal}
          setDeleleModal={setDeleleModal}
          confirmLead={confirmLead}
          getUser={getUser}
        />

        {/* Upload */}
        <UploadLead uploadModal={uploadModal} setUploadModal={setUploadModal} />

        {/* Share */}
        <ConfirmShare
          shareModal={shareModal}
          setShareModal={setShareModal}
          selected={selected}
          setSelected={setSelected}
        />

        {/* Share */}
        <ShareModal distribute={distribute} setDistribute={setDistribute} />

        {/* Search Filter */}
        <SearchFilter
          filterModal={filterModal}
          setFilterModal={setFilterModal}
          applyFilter={applyFilter}
          resetFilter={resetFilter}
          sliderValue1={sliderValue1}
          handleSliderChange1={handleSliderChange1}
          sliderValue2={sliderValue2}
          handleSliderChange2={handleSliderChange2}
          minVal1={1}
          maxVal1={50}
          title1="Total Creditors/Debtors"
          minVal2={1}
          maxVal2={20}
          status={status}
          setStatus={setStatus}
        />

        {/*  */}
        <DeleteMultipleLeads
          deleteManyModal={deleteManyModal}
          setDeleteManyModal={setDeleteManyModal}
          length={selected.length}
          setSelected={setSelected}
          selected={selected}
        />

        {/*  */}
        <SnackBar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
        />
      </div>
    </>
  );
};

export default Home;
