import React, { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../context/AppContext";
import ViewLead from "../components/ViewLead";
import SearchFilter from "../components/SearchFilter";
import Cookies from "js-cookie";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ButtonGroup,
  IconButton,
  TextField,
  TablePagination,
  Button,
} from "@mui/material";
//
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import TuneIcon from "@mui/icons-material/Tune";
import ToggleNav from "../components/ToggleNav";
import ConfirmWithhold from "../components/ConfirmWithhold";
import CreateIcon from "@mui/icons-material/Create";
import NoDataFound from "../components/NoDataFound";
import SnackBar from "../components/Snackbar";
import UpdateLead from "../components/UpdateLead";

const AdminLeads = () => {
  const { user, url, getUser } = useContext(AppContext);
  //
  const [leads, setLeads] = useState([]);
  const [leads2, setLeads2] = useState([]);
  const sharedByAdmin = async () => {
    const dsa_user = Cookies.get("dsa_user");
    try {
      const res = await axios.get(`${url}/user/lead/shared/admin`, {
        headers: {
          token: dsa_user,
        },
      });
      if (res.data.success === true) {
        setLeads(res.data.result);
        setLeads2(res.data.result);
        setTotalCount(res.data.totalCount);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //
  const [viewModal, setViewModal] = useState(false);
  const [details, setDetails] = useState(null);
  const handleViewModal = (item) => {
    setViewModal(true);
    setDetails({
      ...item.lead,
      leadStatus: item.leadStatus,
      comment: item.comment,
    });
  };
  //
  const [filterModal, setFilterModal] = useState(false);
  //
  const [indexSorted, setIndexSorted] = useState(false);
  const indexArrow = useRef(null);
  const sortIndex = () => {
    setIndexSorted(!indexSorted);
    indexArrow.current.classList.toggle("rotate");
    setLeads2(leads2.reverse());
  };
  //
  const [salarySort, setSalarySort] = useState(false);
  const salaryArrow = useRef(null);
  const sortSalary = () => {
    if (salarySort === true) {
      setSalarySort(false);
      const sorted = leads2.sort((a, b) => b.customerSalary - a.customerSalary);
      setLeads2(sorted);
      salaryArrow.current.classList.add("rotate");
    } else if (salarySort === false) {
      setSalarySort(true);
      const sorted = leads2.sort((a, b) => a.customerSalary - b.customerSalary);
      setLeads2(sorted);
      salaryArrow.current.classList.remove("rotate");
    }
  };
  //
  const [scoreSort, setScoreSort] = useState(false);
  const scoreArrow = useRef(null);
  const sortScore = () => {
    if (scoreSort === true) {
      setScoreSort(false);
      const sorted = leads2.sort((a, b) => b.creditScore - a.creditScore);
      setLeads2(sorted);
      scoreArrow.current.classList.add("rotate");
    } else if (scoreSort === false) {
      setScoreSort(true);
      const sorted = leads2.sort((a, b) => a.creditScore - b.creditScore);
      setLeads2(sorted);
      scoreArrow.current.classList.remove("rotate");
    }
  };
  //
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };
  const [jumpToPage, setJumpToPage] = useState(1);
  const handleJumpToPage = () => {
    const totalPages = Math.ceil(totalCount / rowsPerPage);
    const newPage = Math.min(Math.max(jumpToPage - 1, 0), totalPages - 1);
    setPage(newPage);
  };
  //
  useEffect(() => {
    getUser();
  }, []);
  useEffect(() => {
    if (user !== null) {
      sharedByAdmin();
    }
  }, [user]);
  //
  const [withholdModal, setWithholdModal] = useState(false);
  const [withholdLead, setWithholdLead] = useState(null);
  //
  const [sliderValue1, setSliderValue1] = useState([0, 0]);
  const handleSliderChange1 = (e, newValue) => {
    setSliderValue1(newValue);
  };
  //
  const [searchInput, setSearchInput] = useState("");
  const [status, setStatus] = useState("");
  const applyFilter = () => {
    let updated = leads;
    if (searchInput !== "") {
      updated = updated.filter((item) => {
        return (
          item.lead.customerName
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          item.lead.customerNumber
            .toLowerCase()
            .includes(searchInput.toLowerCase()) ||
          item.companyName.toLowerCase().includes(searchInput.toLowerCase())
        );
      });
    }
    if (sliderValue1[0] >= 300 && sliderValue1[1] <= 900) {
      updated = updated.filter((item) => {
        return (
          parseFloat(item.lead.creditScore) >= sliderValue1[0] &&
          parseFloat(item.lead.creditScore) <= sliderValue1[1]
        );
      });
    }
    if (status !== "") {
      updated = updated.filter((item) => {
        return item.leadStatus.toLowerCase() === status.toLowerCase();
      });
    }
    //
    setLeads2(updated);
    setFilterModal(false);
  };
  const resetFilter = () => {
    setSliderValue1([0, 0]);
    setLeads2(leads);
    setFilterModal(false);
    setSearchInput("");
    setStatus("");
  };
  //
  const [updateLeadModal, setUpdateLeadModal] = useState(false);
  const [leadComment, setLeadComment] = useState({
    leadStatus: "",
    comment: "",
    id: "",
    name: "",
  });
  const handleLeadComment = (e) => {
    const { name, value } = e.target;
    setLeadComment({
      ...leadComment,
      [name]: value,
    });
  };
  const startedUpdate = (item) => {
    setLeadComment({
      leadStatus: item.leadStatus,
      comment: item.comment,
      id: item.lead._id,
      name: item.lead.customerName,
    });
    setUpdateLeadModal(true);
  };
  const saveLeadComment = async () => {
    const dsa_user = Cookies.get("dsa_user");
    const { leadStatus, comment, id } = leadComment;
    try {
      const res = await axios.put(
        `${url}/dsa/lead/user/${id}`,
        {
          leadStatus,
          comment,
          userID: user._id,
        },
        {
          headers: {
            token: dsa_user,
          },
        }
      );
      if (res.data.success === true) {
        sharedByAdmin();
        setUpdateLeadModal(false);
        setLeadComment({
          leadStatus: "",
          comment: "",
          id: "",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  //
  const [rowIndex, setRowIndex] = useState(null);
  const handleMouseEnter = (index) => {
    setRowIndex(index);
  };
  const handleMouseLeave = () => {
    setRowIndex(null);
  };
  //
  const [openSnackbar, setOpenSnackbar] = useState(false);
  return (
    <>
      <div className="container-fluid p-2">
        <ToggleNav />
        <div className="top-bar-grid mb-2">
          <div>
            <TextField
              label="Search"
              variant="standard"
              fullWidth
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              onKeyUp={applyFilter}
              autoComplete="off"
              disabled={leads.length === 0 ? true : false}
            />
          </div>
          <div className="text-end">
            <IconButton
              color="primary"
              aria-label="upload file"
              component="span"
              onClick={() => setFilterModal(true)}
              disabled={leads.length === 0 ? true : false}
            >
              <TuneIcon />
            </IconButton>
          </div>
        </div>

        {leads.length !== 0 ? (
          <>
            <TableContainer component={Paper} className="table-container">
              <Table>
                <TableHead>
                  <TableRow className="table-background">
                    <TableCell className="border" sx={{ minWidth: "100px" }}>
                      Sr No
                      <ArrowDownwardIcon
                        onClick={sortIndex}
                        className="indexArrow"
                        ref={indexArrow}
                      />
                    </TableCell>
                    <TableCell className="border" sx={{ minWidth: "200px" }}>
                      Name
                    </TableCell>
                    <TableCell className="border" sx={{ minWidth: "150px" }}>
                      Mobile number
                    </TableCell>
                    <TableCell className="border" sx={{ minWidth: "200px" }}>
                      Company Name
                    </TableCell>
                    <TableCell className="border" sx={{ minWidth: "150px" }}>
                      Salary{" "}
                      <ArrowDownwardIcon
                        onClick={sortSalary}
                        className="salaryArrow"
                        ref={salaryArrow}
                      />
                    </TableCell>
                    <TableCell className="border" sx={{ minWidth: "150px" }}>
                      Credit Score{" "}
                      <ArrowDownwardIcon
                        onClick={sortScore}
                        className="scoreArrow"
                        ref={scoreArrow}
                      />
                    </TableCell>
                    <TableCell className="border" sx={{ minWidth: "150px" }}>
                      Lead status
                    </TableCell>
                    <TableCell className="border" sx={{ minWidth: "200px" }}>
                      Comment
                    </TableCell>
                    <TableCell
                      className="border"
                      sx={{ minWidth: "150px" }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leads2.map((item, index) => {
                    return (
                      <TableRow
                        key={index}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                        className={index === rowIndex ? "table-background" : ""}
                      >
                        <TableCell className="border">{index + 1}</TableCell>
                        <TableCell className="border">
                          <span className="text-clamp">
                            {item.lead.customerName}
                          </span>
                        </TableCell>
                        <TableCell className="border">
                          {item.lead.customerNumber}
                        </TableCell>
                        <TableCell className="border">
                          <span className="text-clamp">
                            {item.lead.companyName}
                          </span>
                        </TableCell>
                        <TableCell className="border">
                          {item.lead.customerSalary}
                        </TableCell>
                        <TableCell className="border">
                          {item.lead.creditScore}
                        </TableCell>
                        <TableCell className="border">
                          {item.leadStatus}
                        </TableCell>
                        <TableCell className="border">
                          <span className="text-clamp">{item.comment}</span>
                        </TableCell>
                        <TableCell className="border">
                          <ButtonGroup>
                            <IconButton
                              color="primary"
                              onClick={() => handleViewModal(item)}
                            >
                              <VisibilityIcon />
                            </IconButton>
                            <IconButton
                              color="primary"
                              onClick={
                                user !== null && user.active === true
                                  ? () => startedUpdate(item)
                                  : () => setOpenSnackbar(true)
                              }
                            >
                              <CreateIcon />
                            </IconButton>
                          </ButtonGroup>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[25, 50, 75, 100]}
            />
            {/*  */}
            <div className="d-flex align-items-end justify-content-end gap-2">
              <TextField
                label="Jump to Page"
                type="number"
                value={jumpToPage}
                onChange={(e) => setJumpToPage(e.target.value)}
                variant="standard"
              />
              <Button
                variant="contained"
                className="text-white"
                color="primary"
                onClick={handleJumpToPage}
              >
                Jump
              </Button>
            </div>
          </>
        ) : (
          <NoDataFound />
        )}

        {/* View */}
        <ViewLead
          viewModal={viewModal}
          setViewModal={setViewModal}
          details={details}
        />

        {/* Search Filter */}
        <SearchFilter
          filterModal={filterModal}
          setFilterModal={setFilterModal}
          applyFilter={applyFilter}
          resetFilter={resetFilter}
          sliderValue1={sliderValue1}
          handleSliderChange1={handleSliderChange1}
          minVal1={300}
          maxVal1={900}
          title1="Credit score"
          status={status}
          setStatus={setStatus}
        />

        {/* Withhold */}
        <ConfirmWithhold
          withholdModal={withholdModal}
          setWithholdModal={setWithholdModal}
          withholdLead={withholdLead}
          sharedByAdmin={sharedByAdmin}
        />

        <SnackBar
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
        />

        <UpdateLead
          updateLeadModal={updateLeadModal}
          setUpdateLeadModal={setUpdateLeadModal}
          saveLeadComment={saveLeadComment}
          leadComment={leadComment}
          handleLeadComment={handleLeadComment}
        />
      </div>
    </>
  );
};

export default AdminLeads;
