import { useContext, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import Navbar from "../components/Navbar";

const Auth = ({ Component }) => {
  const { url, setUser, setUserLeads, setUserLeads2 } = useContext(AppContext);
  const navigate = useNavigate();
  useEffect(() => {
    const dsa_user = Cookies.get("dsa_user");
    if (!dsa_user) {
      navigate("/login", { replace: true });
      return;
    }
    //
    const Authenticate = async () => {
      const res = await axios.get(`${url}/user/auth`, {
        headers: {
          token: dsa_user,
        },
      });
      if (res.data.success === true) {
        setUser(res.data.user);
        setUserLeads(res.data.user.leads);
        setUserLeads2(res.data.user.leads);
      }
    };
    Authenticate();
  }, []);
  return (
    <>
      <Navbar />
      <Component />
    </>
  );
};

export default Auth;
