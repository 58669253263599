import React, { useContext, useMemo, useState } from "react";
import { Button, Modal } from "@mui/material";
import { Box } from "@mui/system";
import { AppContext } from "../context/AppContext";
import Cookies from "js-cookie";
import axios from "axios";

const DeleteMultipleLeads = ({
  deleteManyModal,
  setDeleteManyModal,
  length,
  setSelected,
  selected,
}) => {
  const { url } = useContext(AppContext);
  //
  const [message, setMessage] = useState("");
  const deleteManyLeads = async () => {
    const dsa_user = Cookies.get("dsa_user");
    try {
      const res = await axios.post(
        `${url}/user/lead/delete/many`,
        {
          leads: selected,
        },
        {
          headers: {
            token: dsa_user,
          },
        }
      );
      if (res.data.success === true) {
        setDeleteManyModal(false);
        setSelected([]);
      } else {
        setMessage(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useMemo(() => {
    if (message !== "") {
      setTimeout(() => {
        setMessage("");
      }, 2000);
    }
  }, [message]);
  return (
    <>
      <Modal
        open={deleteManyModal}
        onClose={() => setDeleteManyModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 300,
            width: "calc(100vw - 16px)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: "4px",
            textAlign: "center",
            overflow: "auto",
            maxHeight: "calc(100vh - 16px)",
          }}
        >
          <p className="mb-2">Delete {length} leads?</p>
          <p className="text-danger">{message}</p>
          <div className="d-flex align-items-center justify-content-center gap-2 mt-2">
            <Button
              onClick={deleteManyLeads}
              color="primary"
              variant="contained"
              className="text-white"
            >
              Yes
            </Button>
            <Button
              onClick={() => setDeleteManyModal(false)}
              color="primary"
              variant="contained"
              className="text-white"
            >
              No
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteMultipleLeads;
