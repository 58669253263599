import React from "react";
import { Button, MenuItem, Modal, Select, TextField } from "@mui/material";
import { Box } from "@mui/system";

const UpdateLead = ({
  updateLeadModal,
  setUpdateLeadModal,
  leadComment,
  saveLeadComment,
  handleLeadComment,
}) => {
  return (
    <>
      <Modal
        open={updateLeadModal}
        onClose={() => setUpdateLeadModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 300,
            width: "calc(100vw - 16px)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: "4px",
            overflow: "auto",
            maxHeight: "calc(100vh - 16px)",
          }}
        >
          <>
            <h2 className="mb-2">{leadComment.name}</h2>
            <p>Lead Status</p>
            <Select
              variant="standard"
              name="leadStatus"
              value={leadComment.leadStatus}
              onChange={handleLeadComment}
              fullWidth
            >
              <MenuItem value=""></MenuItem>
              <MenuItem value="uncontactable">uncontactable</MenuItem>
              <MenuItem value="in_progress">in progress</MenuItem>
              <MenuItem value="processed">processed</MenuItem>
              <MenuItem value="not_eligible">not eligible</MenuItem>
            </Select>
          </>
          <div className="mt-4">
            <TextField
              label="Comment"
              multiline
              rows={2}
              variant="standard"
              fullWidth
              name="comment"
              value={leadComment.comment}
              onChange={handleLeadComment}
            />
          </div>
          <div className="d-flex align-items-center justify-content-end gap-2 mt-2">
            <Button
              variant="contained"
              color="danger"
              className="text-white"
              onClick={() => setUpdateLeadModal(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={saveLeadComment}
              className="text-white"
            >
              Save
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default UpdateLead;
