import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Nunito Sans, sans-serif",
  },
  palette: {
    primary: {
      main: "#0CC130",
    },
    danger: {
      main: "#dc1616",
    },
  },
});

export default theme;
