import React, { useContext, useMemo, useState } from "react";
import { Button, Modal, TextField } from "@mui/material";
import { Box } from "@mui/system";
import Cookies from "js-cookie";
import axios from "axios";
import { AppContext } from "../context/AppContext";

const UploadLead = ({ uploadModal, setUploadModal }) => {
  const { url, getUser } = useContext(AppContext);
  const [file, setFile] = useState(null);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");
  const handleFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type === "text/csv" || file.name.endsWith(".csv")) {
        setIsError(false);
        setFile(file);
      } else {
        setIsError(true);
        setMessage("Please select a valid CSV file.");
        e.target.value = null;
        setFile(null);
      }
    }
  };
  //
  const uploadFile = async () => {
    if (file === null) {
      setIsError(true);
      setMessage("Please select a CSV file");
    } else {
      const dsa_user = Cookies.get("dsa_user");
      const formdata = new FormData();
      formdata.append("file", file);
      try {
        const res = await axios.post(`${url}/user/lead/upload`, formdata, {
          headers: {
            token: dsa_user,
          },
        });
        if (res.data.success === true) {
          setFile(null);
          setIsError(false);
          setMessage(res.data.message);
          getUser();
          setTimeout(() => {
            setUploadModal(false);
          }, 2000);
        } else {
          setIsError(true);
          setMessage(res.data.message);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  //
  useMemo(() => {
    if (message !== "") {
      setTimeout(() => {
        setMessage("");
      }, 2000);
    }
  }, [message]);
  return (
    <>
      <Modal
        open={uploadModal}
        onClose={() => setUploadModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: 300,
            width: "calc(100vw - 16px)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 2,
            borderRadius: "4px",
            overflow: "auto",
            maxHeight: "calc(100vh - 16px)",
          }}
        >
          <p>Upload leads using a CSV file</p>
          <TextField
            type="file"
            InputLabelProps={{ shrink: true }}
            fullWidth
            margin="normal"
            variant="standard"
            onChange={handleFile}
          />
          <div className="text-end">
            <p className={isError ? "text-danger" : "text-success"}>
              {message}
            </p>
            <Button
              variant="contained"
              className="text-white"
              onClick={uploadFile}
            >
              Upload
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default UploadLead;
